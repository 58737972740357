.divider {
  border-radius: 1px;
  background: #e4dfd4;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}

.paper {
  position: absolute;
  top: 50%;
  left: 16px;
  z-index: 1000;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 16px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 6%);
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
