.hover {
  opacity: 0;
}

.hover:hover {
  opacity: 1;
}

.handle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  z-index: 1000;
}

.circle-outline {
  background-color: white;
  border: 2px solid var(--primary-color);
}

.close-icon {
  color: var(--primary-color);
  display: flex;
}

.outer-div:hover .close-icon {
  color: white;
}

.outer-div:hover .circle-outline {
  background-color: var(--primary-color);
}

/* may want to figure out the zindex situation at some point
everything inside EdgeLabelRenderer has no pointer events by default
if you have an interactive element, set pointer-events: all
*/
.outer-div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
