.m-v-8px {
  margin-top: 8px;
  margin-bottom: 8px;
}

.m-v-12px {
  margin-top: 12px;
  margin-bottom: 12px;
}

.m-v-16px {
  margin-top: 16px;
  margin-bottom: 16px;
}

.m-v-24px {
  margin-top: 24px;
  margin-bottom: 24px;
}

.m-h-neg-8px {
  margin-left: -8px;
  margin-right: -8px;
}

.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-h-4px {
  margin-left: 4px;
  margin-right: 4px;
}

.m-h-5px {
  margin-left: 5px;
  margin-right: 5px;
}

.m-h-8px {
  margin-left: 8px;
  margin-right: 8px;
}

.m-h-16px {
  margin-left: 16px;
  margin-right: 16px;
}

.m-h-24px {
  margin-left: 24px;
  margin-right: 24px;
}

.m-h-80px {
  margin-left: 80px;
  margin-right: 80px;
}

.m-0px {
  margin: 0;
}

.m-16px {
  margin: 16px;
}

.m-24px {
  margin: 24px;
}

.m-t-4px {
  margin-top: 4px;
}

.m-t-8px {
  margin-top: 8px;
}

.m-t-10px {
  margin-top: 10px;
}

.m-t-12px {
  margin-top: 12px;
}

.m-t-16px {
  margin-top: 16px;
}

.m-t-20px {
  margin-top: 20px;
}

.m-t-24px {
  margin-top: 24px;
}

.m-t-32px {
  margin-top: 32px;
}

.m-r-2px {
  margin-right: 2px;
}

.m-r-4px {
  margin-right: 4px;
}

.m-r-8px {
  margin-right: 8px;
}

.m-r-10px {
  margin-right: 10px;
}

.m-r-12px {
  margin-right: 12px;
}

.m-r-16px {
  margin-right: 16px;
}

.m-b-4px {
  margin-bottom: 4px;
}

.m-b-6px {
  margin-bottom: 6px;
}

.m-b-8px {
  margin-bottom: 8px;
}

.m-b-12px {
  margin-bottom: 12px;
}

.m-b-16px {
  margin-bottom: 16px;
}

.m-b-24px {
  margin-bottom: 24px;
}

.m-b-32px {
  margin-bottom: 32px;
}

/* This is the standard bottom margin for a page
We use a different naming convention because
it's a special case */
.m-b-page {
  margin-bottom: 160px;
}

.m-l-auto {
  margin-left: auto;
}

.m-l-neg-32px {
  margin-left: -32px;
}

.m-l-4px {
  margin-left: 4px;
}

.m-l-8px {
  margin-left: 8px;
}

.m-l-12px {
  margin-left: 12px;
}

.m-l-16px {
  margin-left: 16px;
}
