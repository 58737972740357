:root {
  --no-tool-header-height: 58px;
}

.h-100vh {
  height: 100vh;
}

.min-h-100vh {
  min-height: 100vh;
}

.min-h-100px {
  min-height: 100px;
}

.min-h-246px {
  min-height: 246px;
}

.max-h-100-percent {
  max-height: 100%;
}

.h-40px {
  height: 40px;
}

.h-50px {
  height: 50px;
}

.h-100px {
  height: 100px;
}

.h-450px {
  height: 450px;
}

.h-586px {
  height: 586px;
}

.h-100-percent {
  height: 100%;
}
