.circle-outline {
  border-radius: 50%;
  display: flex;
  width: 19px;
  height: 19px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid var(--primary-color);
}

.close-icon {
  color: var(--primary-color);
}

.outer-div:hover .close-icon {
  color: white;
}

.outer-div:hover .circle-outline {
  background-color: var(--primary-color);
}

/* may want to figure out the zindex situation at some point
everything inside EdgeLabelRenderer has no pointer events by default
if you have an interactive element, set pointer-events: all
*/
.outer-div {
  z-index: 1301;
  width: 40px;
  height: 40px;
  pointer-events: all;
}
