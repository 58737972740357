.loading-wrapper {
  border-radius: 16px;
  border: 1px rgb(228 223 212 / 50%);
  padding: 24px;
}

.loading-dot-spacing {
  margin-right: 3px;
}

.loading-dot-wrapper {
  height: 8px;
  width: 8px;
}

@keyframes dot-highlight {
  0%,
  100% {
    r: 3;
    width: 6px;
    height: 6px;
    fill: rgb(138 45 255 / 30%);
  }

  50% {
    r: 4;
    width: 8px;
    height: 8px;
    fill: #8a2dff;
  }
}

.loading-dot-animate {
  animation: dot-highlight 1.7s ease-in-out infinite;
}
