.input-field-container {
  padding: 10px 12px;
  border: 1px solid var(--button-border);
}

.clickable-input-field-container:hover {
  background: var(--right-side-content-background);
}

.delete-button {
  top: 10;
  right: 0;
}
