.block-draggable-block {
  background-color: white;
  color: black;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.block-draggable-block:hover {
  background-color: var(--button-hover);
}
