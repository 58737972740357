:root {
  --secondary-text-color: #12172999;
  --primary-text-color: #121729;
  --primary-text-color-light: #12172980;
  --primary-color: #8a2dff;
  --primary-button-border-color: #6d12df;
  --app-background-color: #eee9e4;
  --right-side-content-background: #faf8f7;
  --button-secondary-border: #f2e7ff;
  --button-secondary: #fbf7ff;
  --button-border: #e4dfd4;
  --button-hover: #f5f5f5;
  --toolflow-text-disabled-color: #12172961;
  --white: #fff;
  --input-output-border: #d1c4e9;
  --action-focus: rgb(138 45 255 / 12%);
  --error-bg: rgb(244 67 54 / 12%);
  --divider-light: rgb(228 223 212 / 50%);
  --divider: #e4dfd4;
  --blue-50: #e3f2fd;
  --blue-100: #bbdefb;
  --blue-500: #2196f3;
  --background-color: #faf8f7;
  --background-color-hover: #eeeceb;
}

.grey {
  color: var(--secondary-text-color);
}

.background-color {
  background-color: var(--background-color);
}

.background-color-hover:hover {
  background-color: var(--background-color-hover);
}

.white {
  color: white;
}

.black {
  color: black;
}

.off-black {
  color: rgb(0 0 0 / 87%);
}

.primary-purple {
  color: var(--primary-color);
}

.light-grey {
  color: rgb(0 0 0 / 23%);
}

.bg-color-transparent {
  background-color: transparent;
}

.bg-color-transparent-important {
  background-color: transparent !important;
}

.bg-color-right-side-content {
  background-color: var(--right-side-content-background);
}

.bg-color-off-white {
  background-color: rgb(255 255 255 / 50%);
}

.bg-color-white {
  background-color: white;
}

.bg-color-black {
  background-color: black;
}

.bg-color-app-background,
.icon-background {
  background-color: var(--app-background-color);
}

.bg-color-secondary-color {
  background-color: var(--button-secondary-border);
}

.bg-light-grey {
  background-color: rgb(0 0 0 / 23%);
}

.bg-dark-primary-color {
  background-color: var(--action-focus);
}

.bg-dark-error-color {
  background-color: var(--error-bg);
}
