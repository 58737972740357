.custom-elevation {
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
}

.border-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 1px solid var(--button-border);
  border-radius: 16px;
}

.chat-tips-container {
  margin-bottom: -12px;
}
