.agent-message-container {
  padding-left: 30px;
}

.agent-message {
  border-radius: 16px;
  border: 1px solid rgb(228 223 212 / 50%);
  background: white;
  padding: 4px 16px;
}
