.snippet-item {
  height: 100px;
  padding: 10px;
  cursor: pointer;
}

.dropzone-style {
  border-radius: 8px;
  border: 1px dashed var(--button-border);
  background: var(--right-side-content-background);
  display: flex;
  width: 100%;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
}

.file-list-item:hover {
  background: var(--right-side-content-background);
}

.file-list-container {
  padding: 12px 8px 12px 12px;
  border: 1px solid rgb(18 23 41 / 20%);
  border-radius: 8px;
  max-height: 350px;
  overflow-y: scroll;
}

.file-upload-progress {
  position: absolute;
  left: 4px;
  top: 4px;
}
