.expanded-list-outer {
  width: 235px;
  min-width: 235px;
  padding-top: 11px;
  padding-bottom: 15px;
  border-radius: 16px;
  border: 1px solid var(--button-border);
  background: #faf8f7;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  max-height: 100%;
}

.asset-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.list-height {
  height: calc(100% - 32px);
}
