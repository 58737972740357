.cursor-grab {
  cursor: grab;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-crosshair {
  cursor: crosshair;
}
