.scrollable-content {
  overflow: hidden;
  scrollbar-gutter: stable both-edges;
}

.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow-y: scroll;
}

.infinite-scroll-component {
  overflow: hidden !important;
  scrollbar-gutter: stable both-edges;
}

.infinite-scroll-component:hover,
.infinite-scroll-component:active,
.infinite-scroll-component:focus {
  overflow-y: scroll !important;
}

/* scrollable-content-important overwrites ScrollToBottom default behavior */
.scrollable-content-important {
  overflow: hidden !important;
  scrollbar-gutter: stable both-edges;
}

.scrollable-content-important:hover,
.scrollable-content-important:active,
.scrollable-content-important:focus {
  overflow-y: scroll !important;
}

/* For small screens, we always want to show the scroller, because they dont have hover */
@media only screen and (width <= 1200px) {
  .scrollable-content {
    overflow-y: scroll;
  }

  .scrollable-content-important {
    overflow-y: scroll !important;
  }
}

.oflow-hidden {
  overflow: hidden;
}

.oflow-x-hidden {
  overflow-x: hidden;
}

.oflow-y-hidden {
  overflow-y: hidden;
}

.oflow-two-lines {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
  .oflow-two-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.oflow-y-flex {
  flex: 1 1 0%;
  overflow-y: auto;
}

.oflow-auto {
  overflow: auto;
}

.oflow-x-auto {
  overflow-x: auto;
}

.add-scroll-margins {
  scrollbar-gutter: stable both-edges;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(136 136 136 / 20%);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: grab;
  background-color: rgb(136 136 136 / 80%);
}

/* Change the cursor to 'grabbing' when the thumb is clicked and held */
::-webkit-scrollbar-thumb:active {
  cursor: grabbing;
}

.arrow-button {
  display: inline-block;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
  background-color: white !important;
  background-image: url("../../globalTheme/icons/images/arrow.svg");
  background-size: 15px 15px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  border: 1px solid rgb(0 0 0 / 12%) !important;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  color: white;
  font-size: 12px;
  z-index: 1;
}

.arrow-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
}

/* We use the following css selectors to create the faded gradient on the text
.Scroll-to-bottom is on the parent ScrollToBottom. 

We don't put this in a css module, so that we can more easily identify the classnames.

Height: 100% is the only unifying style across all scrolls-to-bottoms, so we put it here */
.scroll-to-bottom {
  height: 100%;
}

.bottom-gradient.bottom-gradient-dark {
  --gradient-end-color: var(--app-background-color);
}

.bottom-gradient {
  --gradient-end-color: var(--white);

  background: linear-gradient(
    180deg,
    rgb(255 255 255 / 0%) 4.38%,
    var(--gradient-end-color) 98.12%
  );
  height: 80px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  display: none;
}

/* We selectively show the bottom gradient based on whether the
arrow class is visible. This indicates that the scroll is not at the bottom */
.scroll-to-bottom .bottom-gradient {
  display: none;
}

.scroll-to-bottom:has(.arrow-button) .bottom-gradient {
  display: block;
}
