.badge {
  font-family: Konnect, var(--font-family);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  position: relative;
  top: -2.5px;
  left: -0.5px;
}

.badge-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 6.5px;
  align-items: center;
  justify-content: center;
}
