.text-generation-bg-color {
  background-color: #19c37d;
}
/* stylelint-disable-next-line selector-class-pattern */
.react-flow__resize-control {
  width: 0;
  height: 0;
  border: 0;
}
/* stylelint-disable-next-line selector-class-pattern */
.react-flow__edge {
  cursor: auto !important;
}
