.add-chip-button {
  border: 1px solid var(--button-secondary-border);
  background: var(--button-secondary);
  color: var(--primary-color);
}

.add-chip-button:hover {
  background-color: var(--button-secondary-border);
}

.asset-button {
  background-color: white;
  border: 1px solid var(--button-border);
  color: var(--toolflow-text-disabled-color);
}

.asset-button:hover {
  background-color: var(--button-hover);
}
