.asset-indicator-width {
  width: 14px;
  min-width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-button-wrapper {
  border-radius: 10px;
  border: 1px solid rgb(220 213 207 / 70%);
}

.wrapper {
  right: 8px;
}

.nested-asset-line {
  margin-top: 6px;
  height: 100%;
  width: 100%;
  position: relative;
  border-left: 1px solid rgb(220 213 207 / 70%);
}

.nested-asset-line::after {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  width: 100%;
  height: 50%;
  border-left: 1px solid rgb(220 213 207 / 70%);
  border-bottom: 1px solid rgb(220 213 207 / 70%);
  border-bottom-left-radius: 4px;
}

.last-nested-asset-line {
  height: 50%;
  border-bottom-left-radius: 4px;
}

.last-nested-asset-line::after {
  height: 100%;
}
