.max-width {
  max-width: 88%;
}

.icon-with-circle {
  border-radius: 8px;
  padding: 4px;
  align-items: center;
  display: flex;
}
