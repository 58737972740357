.asset-max-width {
  max-width: 760px;
  padding-right: 40px;
  padding-left: 40px;
}

.asset-toc-box-shadow {
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
}

.primary-button {
  background-color: var(--primary-color);
  border-radius: 6px;
  border: 1px solid var(--primary-button-border-color);
}

.primary-button:hover {
  background-color: var(--primary-button-border-color);
}
