.user-message-container {
  padding-left: 30px;
}

.user-message {
  border-radius: 16px;
  border: 1px solid #eadaff;
  background: rgb(247 241 255 / 70%);
  padding: 16px;
  margin-top: 20px;
}

.thread-actions {
  right: 20px;
  bottom: -16px;
}
