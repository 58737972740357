.border-on-select {
  border: 2px solid var(--primary-color);
  margin: -2px;
}

.paper-header {
  gap: 4px;
}

.paper-header-container {
  position: relative;
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
}

.iteration-block-header {
  position: relative;
  background-color: var(--blue-50);
  border-color: var(--blue-500);
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
}

.iteration-child-shadow::after {
  content: "";
  width: 100%;
  height: 100%;
  top: -6px;
  left: -6px;
  position: absolute;
  background: var(--blue-100);
  padding: 6px;
  border-radius: 8px;
  z-index: -1;
  box-sizing: content-box;
}

.iteration-child-border::before,
.iteration-child-border::after {
  content: "";
  width: 100%;
  height: 45%;
  left: 0;
  position: absolute;
  border: 1px solid var(--blue-500);
  border-radius: 6px;
  box-sizing: border-box;
}

.iteration-child-border::before {
  top: 0;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.iteration-child-border::after {
  bottom: 0;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.iteration-child-border-arrow::before,
.iteration-child-border-arrow::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 4px;
  border-top: 1px solid var(--blue-500);
  border-left: 1px solid var(--blue-500);
}

.iteration-child-border-arrow::before {
  left: -2px;
  top: 56%;
  rotate: 45deg;
}

.iteration-child-border-arrow::after {
  right: -2px;
  bottom: 56%;
  rotate: -135deg;
}

.paper-header-container:hover .iteration-child-border-arrow::before,
.paper-header-container:hover .iteration-child-border-arrow::after,
.paper-header-container:hover .iteration-child-border::before,
.paper-header-container:hover .iteration-child-border::after {
  display: none;
}
