.list-item {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  color: black;
}

.list-item-active {
  background-color: white;
  border: 1px solid var(--divider);
  color: var(--primary-color);
}

.sidebar-action-button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--primary-color);
  transition: opacity 0.2s ease;
}

.sidebar-action-button:hover {
  background-color: var(--primary-color);
  opacity: 0.8;
}
