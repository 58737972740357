.entity-card {
  /* we need box-sizing border-box because otherwise when loading (or logged out) the card height is 145 not including padding. Instead, we want height to be 145 WITH padding */
  box-sizing: border-box;
  height: 145px;
  max-height: 145px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  color: #121729de;
  border: 1px solid var(--button-border);
  box-shadow: none;
}

.entity-card:hover {
  cursor: pointer;
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
}

.entity-card-header {
  height: 60px;
  padding: 0;
}
