/* stylelint-disable-next-line selector-class-pattern */
.MuiAutocomplete-groupLabel {
  background-color: white;
}
/* stylelint-disable-next-line selector-class-pattern */
.MuiAutocomplete-listbox ::-webkit-scrollbar {
  display: none;
}
/* stylelint-disable-next-line selector-class-pattern */
.MuiAutocomplete-listbox {
  background-color: white;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* stylelint-disable-next-line selector-class-pattern */

/* Add this if we disableRipple */

/* .Mui-focusVisible {
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
} */
