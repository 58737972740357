/* Add parent hover class to the parent div you 
want the component to render if hovered. 
Add show-on-hover to the component that should show 
on hover of parent */
.parent-hover .show-on-hover {
  opacity: 0;
}

.parent-hover:hover .show-on-hover {
  opacity: 1;
}

.parent-hover:hover .border-on-hover {
  border: 2px solid var(--primary-color);
  margin: -2px;
}

/* This is hacky, but sometimes we have nested hovering elements.
  This allows us to differentiate which parent needs to hover */
.parent-hover-1 .show-on-hover-1 {
  opacity: 0;
}

.parent-hover-1:hover .show-on-hover-1 {
  opacity: 1;
}
