.grid-wrapper {
  padding-left: 18px;
  padding-right: 4px;
  padding-bottom: 24px;
  min-width: 100%;
}

.grid-transition {
  transition:
    flex-basis 0.5s ease,
    width 0.5s ease;
}
