.selected {
  color: #121729de;
  background-color: #f3e5f5;
  border-color: #e1bee7;
}

.unselected {
  color: #121729de;
  border-color: var(--button-border);
  background-color: #fff;
}
