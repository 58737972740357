.tiptap-textfield-scroll {
  max-height: 246px;
  flex-grow: 1;
  overflow: hidden auto;
}

.outline {
  border: 1px solid rgb(196 196 196);
  justify-content: center;
  gap: 16px;
  margin: -1px;
  align-items: flex-start;
  transition: border-color 0.3s ease;
}

/* margin is an additon -1px, so when we add 2px, it will be 1px all around */
.outline-hover:hover,
.outline-hover:focus-within {
  margin: -2px;
  border: 2px solid var(--primary-color);
}

.action-button-width {
  min-width: 72px;
}

.slim {
  min-width: 32px;
}

.input-padding {
  padding: 0 8px 0 12px;
  border-radius: 8px;
}
